/* ==========================================================================
   Imports
   ========================================================================== */
global.jQuery = global.$ = require('jquery/dist/jquery.js')
require('./globals/modernizr')
require('jquery-scrollto')
require('slick-carousel')
require('lity')
require('jquery.cookie')
// require('tether')
// require('bootstrap')

/* ==========================================================================
 Globals
 ========================================================================== */
require('./globals/windowVars.zion.js')
require('./globals/mediaIs.zion.js')
require('./globals/resizeEnd.zion.js')
require('./globals/_header.js')

/* ==========================================================================
 Components
 ========================================================================== */
require('./components/infographics.js')
require('./components/video_slider.js')
require('./components/gallery_slider.js')
require('./components/form.js')

