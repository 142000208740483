+function($) {
    // floating label on input
    let $inputs = $('input, textarea, select')

    $inputs.on('focus click', function(){
        let $this = $(this),
            $label = $this.parents('.form-group').find('label')

        $label.addClass('active')
    })

    $inputs.on('blur', function(){
        let $this = $(this),
            $label = $this.parents('.form-group').find('label')

        if($this.val() === ''){ $label.removeClass('active') }
    })

    $inputs.on('input autocomplete', function() {
        let $this = $(this),
            $label = $this.parents('.form-group').find('label')

        if($this.val() !== ''){
            $label.addClass('active')
        } else {
            $label.removeClass('active')
        }
    })

    var wpcf7Elm = document.querySelector( '.wpcf7' );

    wpcf7Elm.addEventListener( 'wpcf7submit', function( event ) {
        $('label').each(function(){
            $(this).removeClass('active')
        })
    }, false );
}(jQuery);