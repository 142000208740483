$('.infographic .btn-pulse').each(function () {
    $(this).on('mouseenter', function () {
        var target = $(this).attr('data-target')+'-hover';
        var svg = document.getElementById("svg");
        var svgDoc = svg.contentDocument;
        var thing = svgDoc.getElementById(target);
        $(thing).toggleClass('hide');
    });

  $(this).on('mouseleave', function () {
    var target = $(this).attr('data-target')+'-hover';
    var svg = document.getElementById("svg");
    var svgDoc = svg.contentDocument;
    var thing = svgDoc.getElementById(target);
    $(thing).toggleClass('hide');
  });
});