+function($) {
    var resizeTimer = setTimeout(function(){}, 1); // utility timer
    var windowWidth = $(window).width();
    var windowHeight = $(window).height();
    $window.add($('html, body')).on('resize', function() {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function() {
            if($window.width() !== windowWidth || $window.height() !== windowHeight) {
                $window.trigger('resizeEnd.zion');
            }
            if($window.height() !== windowHeight) {
                windowHeight = $window.height();
                $window.trigger( 'height.resizeEnd.zion' );
            }
            if($window.width() !== windowWidth) {
                windowWidth = $window.width();
                $window.trigger('width.resizeEnd.zion');
            }
        }, 200);
    });
}(jQuery);