$.media_is = function (className) {
    var class_string = '',
        $item,
        visible;
    var stringcheck = function (item) {
        var allowed = ['xs', 'sm', 'md', 'lg'];
        if (allowed.indexOf(item) === -1) {
            console.warn('$.media_is: The string you passed ("' + item + '") does not match one of [' + allowed.join(',') + '], which are the bootstrap default screen sizes. Unless you have created a .visible-' + item + '-block class in your styles, this will likely return a false positive.');
            console.trace();
        }
    };

    if (className instanceof Array) {
        className.forEach(function (el, index) {
            if (typeof el === 'string') {
                stringcheck(el);
                class_string += 'visible-' + el.trim() + '-block ';
            }
            else {
                console.error('$.media_is: Index ' + index + ' of your array must be a string. You passed in ' + typeof el + '.');
            }
        })
    }
    else if (arguments.length > 1) {
        [].slice.call(arguments).forEach(function (el) {
            if (typeof el === 'string') {
                stringcheck(el);
                class_string += 'visible-' + el.trim() + '-block ';
            }
            else {
                console.error('$.media_is: ' + [].slice.call(arguments).join(', ') + ' cannot be used in this method because it is of type ' + typeof className);
                return false;
            }
        });
    }
    else if (typeof className === 'string') {
        stringcheck(className);
        class_string += 'visible-' + className + '-block';
    }
    else {
        console.error('$.media_is: ' + [].slice.call(arguments).join(', ') + ' cannot be used in this method because it is of type ' + typeof className + '.\nYou may use a string only.');
        return false;
    }

    $item = $('<div class="' + class_string + '"></div>');
    $('body').append($item);
    visible = ($item.css('display') != 'none');
    $item.remove();
    return visible;
};
