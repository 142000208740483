$(document).ready(function () {
  $('.video-slider').slick({
    infinite: true,
    speed: 600,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: '<a class="slick-prev"><i class="fal fa-chevron-left"></i></a>"',
    nextArrow: '<a class="slick-next"><i class="fal fa-chevron-right"></i></a>"',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '45px 0 0',
          arrows: false,
          dots: true
        }
      }
    ]
  });
});