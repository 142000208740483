$(document).ready(function () {
  $('.gallery-slider').slick({
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    dots: true,
    prevArrow: '<a class="slick-prev"><i class="fal fa-chevron-left"></i></a>"',
    nextArrow: '<a class="slick-next"><i class="fal fa-chevron-right"></i></a>"'
  });
});