$(document).ready(function(){
    // Mobile menu display
    $('.btn-menu').on('click', function(){
        $(this).toggleClass('fa-bars')
        $(this).toggleClass('fa-times')
        $('.mobile-nav').toggleClass('active')
    })

    // Modal disclaimer for patient stories Apheresis
    $("a").on("click", function(e){
        if (typeof $.cookie('patient-modal') === 'undefined'){
            var link_text = $(this).text().toLowerCase();

            if(link_text == 'patient stories') {
                e.preventDefault()
                e.stopPropagation()

                let href = $(this).attr('href')
                $('#modal-proceed').attr('href', href)
                $('.c-modal').addClass('active')
            }
        } else {
            window.location.href = link
        }
    })

    $('#modal-proceed').on('click', function(){
        $.cookie('patient-modal', '1', { path: '/' })
    })

    $('.c-modal, .c-modal .close-modal').on('click', function(e){
        e.stopPropagation()
        $('.c-modal').removeClass('active')
        $('#modal-proceed').attr('href', '#')
    })

    $('#modal').on('click', function(e){
        e.stopPropagation()
    })
})